/*
Main include file for the DataFlex JavaScript Engine. This file includes all the standard web 
controls and the core library. Include this file when packing your custom optimized build.
*/

// Global library of helper functions
import { df } from './df.js';
self.df = df;

// Helper classes
import  { HttpRequest } from    './df/ajax/HttpRequest.js';
import  { JSONCall } from   './df/ajax/JSONCall.js';
import { ServerAction, ServerCall } from './df/ServerAction.js';
import { InfoBalloon } from './df/InfoBalloon.js';
import { MobRuleController } from './df/MobRuleController.js';
import { GroupHub } from './df/GroupHub.js';

df.ajax.HttpRequest = HttpRequest;
df.ajax.JSONCall = JSONCall;
df.ServerAction = ServerAction;
df.ServerCall = ServerCall;
df.InfoBalloon = InfoBalloon;
df.MobRuleController = MobRuleController;  
df.GroupHub = GroupHub;

// Web Object classes
import { WebObject } from './df/WebObject.js';
import { WebBaseUIObject } from './df/WebBaseUIObject.js';
import { WebBaseContainer } from './df/WebBaseContainer.js';
import { WebBaseControl } from './df/WebBaseControl.js';
import { WebBaseDEO } from './df/WebBaseDEO.js';
import { WebBaseForm } from './df/WebBaseForm.js';
import { WebBaseFileUpload } from './df/WebBaseFileUpload.js';
import { WebBaseMenu } from './df/WebBaseMenu.js';
import { WebPanel } from './df/WebPanel.js';
import { BaseApp } from './df/BaseApp.js';
import { WebApp } from './df/WebApp.js';
import { LocalApp } from './df/LocalApp.js';
import { WebBaseView } from './df/WebBaseView.js';
import { WebView } from './df/WebView.js';
import { WebWindow } from './df/WebWindow.js';
import { WebModalDialog } from './df/WebModalDialog.js';
import { WebButton } from './df/WebButton.js';
import { WebLabel } from './df/WebLabel.js';
import { WebForm } from './df/WebForm.js';
import { WebDateForm } from './df/WebDateForm.js';
import { WebDynamicObjectContainer } from './df/WebDynamicObjectContainer.js';
import { WebCheckbox } from './df/WebCheckbox.js';
import { WebEdit } from     './df/WebEdit.js';
import { WebCombo } from './df/WebCombo.js';
import { WebBaseSelectionForm } from './df/WebBaseSelectionForm.js';
import { WebSuggestionForm } from './df/WebSuggestionForm.js';
import { WebSelectionForm } from './df/WebSelectionForm.js';
import { WebTagsForm } from './df/WebTagsForm.js';
import { WebListExpandPanel } from './df/WebListExpandPanel.js';
import { WebList } from './df/WebList.js';
import { WebListHeaderView } from './df/WebListHeaderView.js';
import { WebListRowModel } from './df/WebListRowModel.js';
import { WebListSwipeButton } from './df/WebListSwipeButton.js';
import { WebGrid } from './df/WebGrid.js';
import { WebMuliSelectList } from './df/WebMultiSelectList.js';
import { WebColumn_mixin } from './df/WebColumn_mixin.js';
import { WebColumn } from './df/WebColumn.js';
import { WebColumnCombo } from './df/WebColumnCombo.js';
import { WebColumnCheckbox } from './df/WebColumnCheckbox.js';
import { WebColumnDate } from './df/WebColumnDate.js';
import { WebColumnButton } from './df/WebColumnButton.js';
import { WebColumnImage } from './df/WebColumnImage.js';
import { WebColumnSuggestion } from './df/WebColumnSuggestion.js';
import { WebColumnLink } from './df/WebColumnLink.js';
import { WebColumnTags } from './df/WebColumnTags.js';
import { WebColumnSelectionIndicator } from './df/WebColumnSelectionIndicator.js';
import { WebCommandBar } from './df/WebCommandBar.js';
import { WebMenuBar } from './df/WebMenuBar.js';
import { WebMenuGroup } from './df/WebMenuGroup.js';
import { WebToolBar } from './df/WebToolBar.js';
import { WebActionBar } from './df/WebActionBar.js';
import { WebMenuItem } from './df/WebMenuItem.js';
import { WebMenuItemCheckbox } from './df/WebMenuItemCheckbox.js';
import { WebMenuColumnBase } from './df/WebMenuColumnBase.js';
import { WebMenuColumnList } from './df/WebMenuColumnList.js';
import { WebMenuColumnRestorer } from './df/WebMenuColumnRestorer.js';
import { WebMenuGroupingSelector } from './df/WebMenuGroupingSelector.js';
import { WebCardContainer } from './df/WebCardContainer.js';
import { WebCard } from './df/WebCard.js';
import { WebTabContainer } from './df/WebTabContainer.js';
import { WebTabPage } from './df/WebTabPage.js';
import { WebDatePicker } from './df/WebDatePicker.js';
import { WebTreeView } from './df/WebTreeView.js';
import { WebIFrame } from './df/WebIFrame.js';
import { WebImage } from './df/WebImage.js';
import { WebHtmlBox } from './df/WebHtmlBox.js';
import { WebRadio } from './df/WebRadio.js';
import { WebSlider } from './df/WebSlider.js';
import { WebSpacer } from './df/WebSpacer.js';
import { WebHorizontalLine } from './df/WebHorizontalLine.js';
import { WebTimer } from './df/WebTimer.js';
import { WebProgressBar } from './df/WebProgressBar.js';
import { WebFileUploadButton } from './df/WebFileUploadButton.js';
import { WebFileUploadForm } from './df/WebFileUploadForm.js';
import { WebGroup } from './df/WebGroup.js';
import { WebBreadcrumb } from './df/WebBreadcrumb.js';
import { WebFloatingPanel } from './df/WebFloatingPanel.js';
import { WebMenuButton } from './df/WebMenuButton.js';
import { WebMenuList } from './df/WebMenuList.js';
import { WebColorPicker } from './df/WebColorPicker.js';
import { WebColorForm } from './df/WebColorForm.js';
import { WebColumnHighlight } from './df/WebColumnHighlight.js';
import { WebAccordionContainer } from './df/WebAccordionContainer.js';
import { WebAccordionCard } from './df/WebAccordionCard.js';
import { WebHtmlList, WebHtmlListRowModel } from './df/WebHtmlList.js';
import { WebGeoLocation } from './df/WebGeoLocation.js';
import { WebContextMenu } from './df/WebContextMenu.js';
import { WebDrawing } from './df/WebDrawing.js';
import { WebDropZone } from './df/dragdrop/WebDropZone.js';
import { WebDragDropHelper } from './df/dragdrop/WebDragDropHelper.js';
import { WebDragDropFileHelper } from './df/dragdrop/WebDragDropFileHelper.js';
import { LocalWebDragDropProxy } from './df/dragdrop/WebDragDropHelper.js';
import { WebWidget } from './df/dashboard/WebWidget.js';
import { WebWidgetContainerDragDropHelper } from './df/dashboard/WebWidgetContainerDragDropHelper.js';
import { WebWidgetContainerInternal } from './df/dashboard/WebWidgetContainerInternal.js';
import { WebWidgetContainer } from './df/dashboard/WebWidgetContainer.js';

df.WebObject = WebObject;
df.WebBaseUIObject = WebBaseUIObject;
df.WebBaseContainer = WebBaseContainer;
df.WebBaseControl = WebBaseControl;
df.WebBaseDEO = WebBaseDEO;
df.WebBaseForm = WebBaseForm;
df.WebBaseFileUpload = WebBaseFileUpload;
df.WebBaseMenu = WebBaseMenu;
df.WebPanel = WebPanel;
df.BaseApp = BaseApp;
df.WebApp = WebApp;
df.LocalApp = LocalApp;
df.WebBaseView = WebBaseView;
df.WebView = WebView;
df.WebWindow = WebWindow;
df.WebModalDialog = WebModalDialog;
df.WebButton = WebButton;
df.WebLabel = WebLabel;
df.WebForm = WebForm;
df.WebDateForm = WebDateForm;
df.WebDynamicObjectContainer = WebDynamicObjectContainer;
df.WebCheckbox = WebCheckbox;
df.WebEdit = WebEdit;
df.WebBaseSelectionForm = WebBaseSelectionForm;
df.WebCombo = WebCombo;
df.WebSuggestionForm = WebSuggestionForm;
df.WebSelectionForm = WebSelectionForm;
df.WebTagsForm = WebTagsForm;
df.WebListExpandPanel = WebListExpandPanel;
df.WebList = WebList;
df.WebListHeaderView = WebListHeaderView;
df.WebListRowModel = WebListRowModel;
df.WebListSwipeButton = WebListSwipeButton;
df.WebGrid = WebGrid;
df.WebMuliSelectList = WebMuliSelectList;
df.WebColumn_mixin = WebColumn_mixin;
df.WebColumn = WebColumn;
df.WebColumnCombo = WebColumnCombo;
df.WebColumnCheckbox = WebColumnCheckbox;
df.WebColumnDate = WebColumnDate;
df.WebColumnButton = WebColumnButton;
df.WebColumnImage = WebColumnImage;
df.WebColumnSuggestion = WebColumnSuggestion;
df.WebColumnLink = WebColumnLink;
df.WebColumnTags = WebColumnTags;
df.WebColumnSelectionIndicator = WebColumnSelectionIndicator;
df.WebCommandBar = WebCommandBar;
df.WebMenuBar = WebMenuBar;
df.WebMenuGroup = WebMenuGroup;
df.WebToolBar = WebToolBar;
df.WebActionBar = WebActionBar;
df.WebMenuItem = WebMenuItem;
df.WebMenuItemCheckbox = WebMenuItemCheckbox;
df.WebMenuColumnBase = WebMenuColumnBase;
df.WebMenuColumnList = WebMenuColumnList;
df.WebMenuColumnRestorer = WebMenuColumnRestorer;
df.WebMenuGroupingSelector = WebMenuGroupingSelector;
df.WebCardContainer = WebCardContainer;
df.WebCard = WebCard;
df.WebTabContainer = WebTabContainer;
df.WebTabPage = WebTabPage;
df.WebDatePicker = WebDatePicker;
df.WebTreeView = WebTreeView;
df.WebIFrame = WebIFrame;
df.WebImage = WebImage;
df.WebHtmlBox = WebHtmlBox;
df.WebRadio = WebRadio;
df.WebSlider = WebSlider;
df.WebSpacer = WebSpacer;
df.WebHorizontalLine = WebHorizontalLine;
df.WebTimer = WebTimer;
df.WebProgressBar = WebProgressBar;
df.WebFileUploadButton = WebFileUploadButton;
df.WebFileUploadForm = WebFileUploadForm;
df.WebGroup = WebGroup;
df.WebBreadcrumb = WebBreadcrumb;
df.WebFloatingPanel = WebFloatingPanel;
df.WebMenuButton = WebMenuButton;
df.WebMenuList = WebMenuList;
df.WebColorPicker = WebColorPicker;
df.WebColorForm = WebColorForm;
df.WebColumnHighlight = WebColumnHighlight;
df.WebAccordionContainer = WebAccordionContainer;
df.WebAccordionCard = WebAccordionCard;
df.WebHtmlList = WebHtmlList;
df.WebHtmlListRowModel = WebHtmlListRowModel;
df.WebGeoLocation = WebGeoLocation;
df.WebContextMenu = WebContextMenu;
df.WebDrawing = WebDrawing;
df.WebDropZone = WebDropZone;
df.WebDragDropHelper = WebDragDropHelper;
df.WebDragDropFileHelper = WebDragDropFileHelper;
df.LocalWebDragDropProxy = LocalWebDragDropProxy;
df.WebWidget = WebWidget;
df.WebWidgetContainerDragDropHelper = WebWidgetContainerDragDropHelper;
df.WebWidgetContainerInternal = WebWidgetContainerInternal;
df.WebWidgetContainer = WebWidgetContainer;




