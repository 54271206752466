﻿import { WebBaseMenu } from './WebBaseMenu.js';
import { WebMenuItemCheckbox } from './WebMenuItemCheckbox.js';
import { df } from '../df.js';
/*
Class:
    df.WebMenuItem
Extends:
    df.WebBaseControl

This is the client-side representation of the WebMenuBar class that wraps the WebMenuItems. It 
generates the HTML for the ul element that wraps the li elements of the WebMenuItems. The CSS 
(class: WebMenuBar) Determines how the menu is shown and how the items will look depending on their 
place in the menu structure.

The menu system works with an abstraction level where menu items are represented as data objects 
(not Web Objects). There are provider objects that provide a menu tree and there are listener 
objects that display a menu. A grouphub (based on psGroupName) provides communication between these 
objects allowing multiple providers and multiple listeners to work on a single menu group. Mose menu 
classes are both provider and listener where the WebMenuGroup is only a provider.
    
   
Revision:
    2011/10/04  (HW, DAW) 
        Initial version.
    2015/01/19  (HW, DAW)
        Full refactoring of the menu system where the menu class is now completely responsible for 
        the rendering and menu items are separated. The menu class now only knows menu items as 
        abstract data objects provider by a menu provider (can be the menu class itself) through a 
        group hub.
*/

/*
This class represents a menubar inside a command bar. It belongs to the server-side cWebMenuBar 
class and implements its functionality on the client. The CSS (class: WebMenuBar) defines the looks 
of this control and the WebMenuItem's that it wraps.
*/

export class WebMenuBar extends WebBaseMenu {
    constructor(sName, oParent) {
        super(sName, oParent);


        this.prop(df.tBool, "pbShowOnHover", false);
        this.prop(df.tBool, "pbFocusable", false);

        // @privates
        this._bShownByHover = false;
        this._sSelectedItem = null;
        this._sExpanded = null;
        this._tHideTimeout = null;

        //  Configure base classes
        this._bIsMenuListener = true;

        this._bWrapDiv = true;
        this._bRenderChildren = false;
        this._sControlClass = "WebMenuBar";
    }

    //  - - - Rendering - - -

    /*
    The WebMenuBar consists of a single ul element that is wrapped by the div generated by its 
    base class.
    
    @param  aHtml   String builder array to add the HTML to.
    
    @private
    */
    openHtml(aHtml) {
        super.openHtml(aHtml);

        aHtml.push('<ul class="WebBarRoot" tabindex="0">');

        this.genMenuHtml(aHtml);
    }

    /*
    Properly close the opened HTML elements.
    
    @param  aHtml   String builder array to add the HTML to.
    
    @private
    */
    closeHtml(aHtml) {
        aHtml.push('</ul>');
        aHtml.push('<div style="clear: both"></div>');

        super.closeHtml(aHtml);
    }

    afterRender() {
        //  We need to get a reference to the ul element
        this._eControl = df.dom.query(this._eElem, "ul");

        super.afterRender();

        df.dom.on("click", this._eControl, this.onMenuClick, this);
        df.dom.on("mouseover", this._eControl, this.onMouseOver, this);
        df.dom.on("mouseout", this._eControl, this.onMouseOut, this);
        df.dom.on("keydown", this._eControl, this.onKeyDown, this);

    }

    // - - - - - - Menu HTML Generation - - - - - -

    genMenuHtml(aHtml) {
        const aMenu = this.getMenu();

        for (let i = 0; i < aMenu.length; i++) {
            this.genItemHtml(aHtml, aMenu[i], i.toString(), true);
        }
    }


    refreshMenu() {
        const aHtml = [];

        if (this._eControl) {
            this.genMenuHtml(aHtml);

            this._eControl.innerHTML = aHtml.join('');
            this.expandPath(this._sExpanded || "", true);
        }
    }


    // - - - - - - Menu Logic - - - - - -

    onMenuClick(oEvent) {
        let eElem, tItem = null;

        if (!this.isEnabled()) {
            return;
        }

        this.notifyReceived();
        this._bShownByHover = false;

        //  Determine the clicked menu item
        eElem = oEvent.getTarget();
        while (!tItem && eElem && eElem !== this._eElem) {
            if (eElem.hasAttribute("data-df-path")) {
                tItem = this.getItemByPath(eElem.getAttribute("data-df-path"));
            }

            eElem = eElem.parentNode;
        }

        //  Actual click logic
        if (tItem) {
            this.selectItem(tItem);

            if (tItem.bEnabled) {
                if (this.isExpanded(tItem)) {
                    this.collapseItem(tItem);
                } else {
                    this.expandItem(tItem);
                }

                //  The handler object will process the actual click (this is a different object for dynamically created items)
                if (tItem._oHandler.itemClick(tItem, function (bExec) {
                    if (bExec && !(tItem.hRef instanceof WebMenuItemCheckbox)) {
                        this.collapseAll();
                    }
                }, this)) {
                    this.getWebApp().returnFocus();
                }

            }

            oEvent.stop();
        }
    }

    onMouseOver(oEvent) {
        let eElem, tItem = null;

        if (!this.isEnabled()) {
            return;
        }

        this.notifyReceived();

        //  Determine the clicked menu item
        eElem = oEvent.getTarget();
        while (!tItem && eElem && eElem !== this._eElem) {
            if (eElem.hasAttribute("data-df-path")) {
                tItem = this.getItemByPath(eElem.getAttribute("data-df-path"));
            }

            eElem = eElem.parentNode;
        }

        if (tItem) {
            this.selectItem(tItem);

            if (this._sExpanded || this.pbShowOnHover) {
                if (!this._sExpanded) {
                    this._bShownByHover = true;
                }
                if (tItem.aChildren.length > 0) {
                    this.expandItem(tItem);
                } else {
                    this.collapseSubItems(tItem);
                }
            }

            oEvent.stop();
        }
    }

    onMouseOut(oEvent) {
        this.notifyLost(true);

        oEvent.stop();
    }

    selectItem(tItem) {
        let eItem;

        //  Deselect if needed
        if (this._sSelectedItem && this._sSelectedItem !== tItem._sPath && this._sSelectedItem !== tItem._sPath) {
            eItem = this.getItemElemByPath(this._sSelectedItem);
            if (eItem) {
                df.dom.removeClass(eItem, "WebItm_Selected");
            }

            this._sSelectedItem = null;
        }

        //  Mark new item as selected
        if (tItem.bEnabled) {
            eItem = this.getItemElemByPath(tItem._sPath);
            df.dom.addClass(eItem, "WebItm_Selected");
            this._sSelectedItem = tItem._sPath;
        }
    }

    isExpanded(tItem) {
        if (this._sExpanded && this._sExpanded.substr(0, tItem._sPath.length) === tItem._sPath) {
            return true;
        }

        return false;
    }

    expandPath(sPath, bWithoutTransition) {
        let aPath = sPath.split('.');
        let sConstructPath = "";

        if (bWithoutTransition) df.dom.addClass(this._eElem, "WebNoTransition");

        for (let i = 0; i < aPath.length; i++) {
            sConstructPath += (i > 0 ? "." + aPath[i] : aPath[i]);
            let tItem = this.getItemByPath(sConstructPath);
            if (tItem) {
                this.expandItem(tItem);
            }
        }

        if (bWithoutTransition) df.dom.removeClass(this._eElem, "WebNoTransition");
    }

    expandItem(tItem) {
        let eItem, eLast;

        this.collapseSubItems(tItem);

        if (tItem.aChildren.length > 0 && tItem.bEnabled) {
            this._sExpanded = tItem._sPath;
            eItem = this.getItemElemByPath(this._sExpanded);
            while (eItem && eItem !== this._eElem) {
                if (eItem.hasAttribute("data-df-path")) {
                    df.dom.addClass(eItem, "WebItm_Expanded");

                    eLast = eItem;
                }

                eItem = eItem.parentNode;
            }


            if (eLast) {
                //  Position sub menu
                const eSub = eLast.lastChild;
                const oRect = eLast.getBoundingClientRect();

                let iLeft = oRect.left;
                const iViewport = df.sys.gui.getViewportWidth();


                if (iLeft + eSub.offsetWidth > iViewport) {
                    iLeft = oRect.right - eSub.offsetWidth;

                    if (iLeft < 0) {
                        iLeft = 5;
                    }
                }

                eSub.style.top = oRect.bottom + "px";
                eSub.style.left = iLeft + "px";
            }
        }
    }

    collapseSubItems(tItem) {
        let eItem;
        const sTarget = tItem._sPath;

        if (this._sExpanded && tItem._sPath !== this._sExpanded) {
            eItem = this.getItemElemByPath(this._sExpanded);

            if (eItem) {
                //  Collapse all items that are not in the path
                while (eItem && eItem !== this._eElem) {

                    if (eItem.hasAttribute("data-df-path")) {
                        this._sExpanded = eItem.getAttribute("data-df-path");

                        if (this._sExpanded !== sTarget.substr(0, this._sExpanded.length)) {
                            df.dom.removeClass(eItem, "WebItm_Expanded");
                        } else {
                            break;
                        }
                    }
                    eItem = eItem.parentNode;
                }

            } else {
                //  Just collapse everything as something has changed
                this.collapseAll();
            }
        }
    }

    collapseItem(tItem) {
        let sTarget = tItem._sPath;

        if (sTarget.indexOf(".") > 0) {
            sTarget = sTarget.substr(0, sTarget.lastIndexOf("."));

            this.expandItem(this.getItemByPath(sTarget));
        } else {
            //  Just collapse everything as something has changed
            this.collapseAll();
        }
    }

    /*
    @client-action
    */
    collapseAll() {
        const aItems = df.dom.query(this._eControl, 'li.WebItm_Expanded', true);

        for (let i = 0; i < aItems.length; i++) {
            df.dom.removeClass(aItems[i], "WebItm_Expanded");
        }

        this._sExpanded = null;
    }

    notifyLost(bMouseOut) {
        const that = this;

        if (!bMouseOut || this._bShownByHover) {
            if (this._tHideTimeout) {
                clearTimeout(this._tHideTimeout);
            }
            this._tHideTimeout = setTimeout(function () {
                var eItem;

                that._tHideTimeout = null;
                that.collapseAll();

                //  Deselect item
                if (that._sSelectedItem) {
                    eItem = that.getItemElemByPath(that._sSelectedItem);
                    if (eItem) {
                        df.dom.removeClass(eItem, "WebItm_Selected");
                    }

                    that._sSelectedItem = null;
                }

                if (bMouseOut && that._bShownByHover) {
                    that.getWebApp().returnFocus();
                }
            }, 150);
        }
    }

    notifyReceived() {
        if (this._tHideTimeout) {
            clearTimeout(this._tHideTimeout);
            this._tHideTimeout = null;
        }
    }

    moveParent() {
        let sTarget = this._sSelectedItem;

        if (sTarget) {
            if (sTarget.indexOf(".") > 0) {
                sTarget = sTarget.substr(0, sTarget.lastIndexOf("."));

                const tItem = this.getItemByPath(sTarget);
                if (tItem) {
                    this.collapseItem(tItem);
                    this.selectItem(tItem);
                }
            }
        }
    }

    moveSibling(iDelta) {
        let tItem, bFirst = true;

        if (this._sSelectedItem) {
            const aPath = this._sSelectedItem.split(".");


            while (bFirst || (tItem && !tItem.bEnabled)) {
                aPath[aPath.length - 1] = parseInt(aPath[aPath.length - 1], 10) + iDelta;
                const sTarget = aPath.join(".");
                tItem = this.getItemByPath(sTarget);

                bFirst = false;
            }
            if (tItem) {
                this.expandItem(tItem);
                this.selectItem(tItem);
            }
        }
    }

    moveChild() {
        let sTarget, tItem, bFirst = true;

        if (this._sSelectedItem) {
            sTarget = this._sSelectedItem + ".0";
        } else {
            sTarget = "0";
        }

        const aPath = sTarget.split(".");

        //  Move on to the first enabled item
        while (bFirst || (tItem && !tItem.bEnabled)) {
            sTarget = aPath.join(".");
            tItem = this.getItemByPath(sTarget);

            aPath[aPath.length - 1] = parseInt(aPath[aPath.length - 1], 10) + 1;
            bFirst = false;
        }

        if (tItem) {
            this.expandItem(tItem);
            this.selectItem(tItem);
        }

    }

    /* 
    Makes sure a specific item is visible by expanding its parent or showing the root. This is used by 
    the designer.
    
    @param  tItem   Menu item.
    */
    showItem(tItem) {
        this.expandPath(tItem._sPath, false);
    }


    /*
    
    @param  oEvent  The event object.
    
    */
    onKeyDown(oEvent) {
        let bRes = false;
        const bRoot = (this._sSelectedItem && this._sSelectedItem.indexOf(".") < 0);

        if (oEvent.matchKey(df.settings.menuKeys.moveUp)) {
            if (!bRoot) {
                this.moveSibling(-1);
            }
            bRes = true;
        } else if (oEvent.matchKey(df.settings.menuKeys.moveDown)) {
            if (bRoot) {
                this.moveChild();
            } else {
                this.moveSibling(1);
            }
            bRes = true;
        } else if (oEvent.matchKey(df.settings.menuKeys.moveLeft)) {
            if (bRoot) {
                this.moveSibling(-1);
            } else {
                this.moveParent();
            }
            bRes = true;
        } else if (oEvent.matchKey(df.settings.menuKeys.moveRight)) {
            if (bRoot) {
                this.moveSibling(1);
            } else {
                this.moveChild();
            }
            bRes = true;
        } else if (oEvent.matchKey(df.settings.formKeys.submit)) {
            if (this._sSelectedItem) {
                const tItem = this.getItemByPath(this._sSelectedItem);
                if (tItem) {
                    bRes = true;
                    tItem._oHandler.itemClick(tItem, function (bExec) {
                        if (bExec) {
                            this.collapseAll();
                            this.getWebApp().returnFocus();
                        }
                    }, this);
                }

            }
        }

        if (bRes) {
            oEvent.stop();
            this._bShownByHover = false;
        }
    }


    onFocus(oEvent) {
        //df.WebBaseControl.base.onFocus.call(this, oEvent);

        if (!this.isEnabled()) {
            return;
        }

        this.notifyReceived();

        if (this._eElem) {
            df.dom.addClass(this._eElem, "WebCon_Focus");

            if (!this._sSelectedItem) {
                this.moveChild();
            }
        }
        if (this.pbFocusable) {
            this.objFocus();
        }


        this.fire("OnFocus");

        this._bHasFocus = true;
    }

    onBlur(oEvent) {
        //df.WebBaseControl.base.onBlur.call(this, oEvent);

        this.notifyLost(false);

        if (this._eElem) {
            df.dom.removeClass(this._eElem, "WebCon_Focus");
        }

        this.fire("OnBlur");

        this._bHasFocus = false;
    }
}